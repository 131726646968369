import { Component, OnInit,ChangeDetectorRef } from '@angular/core'; 
import { ActivatedRoute } from 'node_modules/@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalDirective } from 'angular-bootstrap-md'; 
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { Router, NavigationExtras } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contact = {
    name: '',
    email: '',
    contact:'',
    subject:'',
    message: '',
    status: '0',
    date: moment().format('YYYY-MM-DD')
  };
  constructor(
    private router: Router,
    public util: UtilService,
    public api: ApiService, 
    private route: ActivatedRoute, 
  ) {
    console.log('general', this.util.general);
  }

  ngOnInit(): void {

  }

  submit() {
    console.log('contact', this.contact);
    if (this.contact.name === '' || this.contact.email === '' || this.contact.message === '' || this.contact.contact === '' || this.contact.subject === '') {
      this.util.errorMessage(this.util.translate('All Fields are required'));
      return false;
    }
    const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailfilter.test(this.contact.email)) {
      this.util.errorMessage(this.util.translate('Please enter valid email'));
      return false;
    }
     	 	 	  	 	
    const para = {
      name: this.contact.name,
      email: this.contact.email,
      contact:this.contact.contact,  
      message:this.contact.message,
      subject:this.contact.subject,
      date:'',
      status:0,
      extra_field:'' 
    };

    this.util.start(); 
      this.api.post('Bookingdisorder/Submitsave', para).then((data: any) => {
        //this.util.stop();
  
        if(this.contact.email!=='')
        { 
            const param = {
              email: this.contact.email
            };
            this.api.post('users/BookingResponse', param).then((data: any) => {
              //console.log(data);
              //this.router.navigate(['thanks']);
  
  
            }, error => {
              console.log(error);
            });
        }

        this.contact.email=''; 
  
        this.router.navigate(['/thanks']);
  
  
        if (data && data.status === 200) {
  
          this.success();
        } else {
          this.util.errorMessage(this.util.translate('Something went wrong'));
        }
      }, error => {
        console.log(error);
        this.util.stop();
        this.util.errorMessage(this.util.translate('Something went wrong'));
      });
       
  }


  success() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });

    Toast.fire({
      icon: 'success',
      title: this.util.translate('message sent successfully')
    });
  }

}
