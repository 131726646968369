
<section class="height-100vh d-flex align-items-center page-section-ptb contactUsscreen" style="background-color: #edf2f5;">
    <div class="container">
        <div class="row justify-content-center no-gutters vertical-align">
          <div class="col-lg-8 col-md-6 card">
            <div class="pt-30 pr-30 pb-30 pl-30 clearfix text-center">
              <img src="assets/icons/icon-forget-pswrd.png" class="mb-20">
              <h3>{{util.translate('Get In Touch')}}</h3>
              <p class="mt-10">{{util.translate('Do you have any questions? Our experts will be happy to help.')}}</p>
              <div class="row mt-30">
                    <div class="col-md-6 section-field mb-20">
                      <input type="text" class="form-control" [(ngModel)]="contact.name" [placeholder]="util.translate('Full Name')">
                </div>
                <div class="col-md-6 section-field mb-20">
                  <input type="text" class="form-control" [(ngModel)]="contact.email" [placeholder]="util.translate('Email ID')">
                </div>
                <div class="col-md-6 section-field mb-20">
                <input type="text" [placeholder]="util.translate('Enter Your Contact No.')" [(ngModel)]="contact.contact"  class="form-control" >
                </div>
                <div class="col-md-6 section-field mb-20">
                <input type="text" [placeholder]="util.translate('Enter Your Subject')" [(ngModel)]="contact.subject"   class="form-control" >
                </div>
                            <div class="col-md-12 section-field mb-20">
                              <textarea class="form-control" rows="5" [(ngModel)]="contact.message"
                              [placeholder]="util.translate('Write Message..')"></textarea>
                </div>
                <div class="col-md-6 section-field mb-20">
                  <a href="#" class="button" style="width: 100%;" (click)="submit()" >Submit</a>
              </div>
            </div>
              </div>
            </div>
        </div> 
    </div>
  </section>



  <!-- 
  <div class="head_title">
    <label> </label>
  </div>

  <div class="row">

    <div class="col-lg-6">

      <input type="text" class="form-control" [(ngModel)]="contact.name" [placeholder]="util.translate('Full Name')">

      <input type="text" class="form-control" [(ngModel)]="contact.email" [placeholder]="util.translate('Email ID')">

      <textarea class="form-control" rows="5" [(ngModel)]="contact.message"
        [placeholder]="util.translate('Write Message..')"></textarea>

      <button type="button" mdbWavesEffect mdbBtn class="btn" (click)="submit()">{{util.translate('Submit')}} </button>

    </div>

    <div class="col-lg-6">
      <div class="address_div" *ngIf="util.general && util.general.address">

        <div class="flex_box">
          <mdb-icon fas icon="home"></mdb-icon>
          <label> {{util.general.address}}
          </label>
        </div>
        <div class="flex_box">
          <mdb-icon fas icon="home"></mdb-icon>
          <label> {{util.general.city}} - {{util.general.country}} - {{util.general.zip}}
          </label>
        </div>
        <div class="flex_box">
          <mdb-icon fas icon="envelope"></mdb-icon>
          <label> {{util.general.email}} </label>
        </div>
        <div class="flex_box">
          <mdb-icon fas icon="phone-alt"></mdb-icon>
          <label> {{util.general.mobile}} </label>
        </div>
        <div class="flex_box">
          <mdb-icon fas icon="print"></mdb-icon>
          <label for=""> {{util.general.email}} </label>
        </div>

      </div>
    </div>
  </div> -->